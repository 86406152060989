import { Box, Stack, Typography, Divider, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import ReactMarkdown from 'react-markdown'

import Logo from "../../assets/Images/logo.ico"
import { Copy, ThumbsDown, ThumbsUp } from 'phosphor-react'
import { sendFeedback } from '../../utils/api'

const PromptMsg = ({ el }) => {
  return (
    <>
      <Box sx={{ py: 2.5, px: 1 }}>
        <Typography variant="h4">
          {el.message}
        </Typography>
      </Box>
    </>
  )
}

const AnswerMsg = ({ el }) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(el.message);
      console.log('Message copied to clipboard');
      await sendFeedback('copy', el.message_id);
    } catch (err) {
      console.error('Failed to copy message: ', err);
    }
  };

  const handleGoodResponse = async () => {
    try {
      await sendFeedback('good_response', el.message_id);
      console.log('Good response feedback sent');
    } catch (err) {
      console.error('Failed to send good response feedback: ', err);
    }
  };

  const handleReport = async () => {
    try {
      await sendFeedback('report', el.message_id);
      console.log('Report feedback sent');
    } catch (err) {
      console.error('Failed to send report feedback: ', err);
    }
  };

  // Custom renderer for links
  const renderers = {
    a: ({ node, ...props }) => (
      <a target="_blank" rel="noopener noreferrer" {...props} />
    ),
  };

  return (
    <>
      <Stack direction="column" padding={1} spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <img
            alt={"Logo"}
            src={Logo}
            style={{
              height: 35,
              width: 35
            }}
          />
          <Typography variant="h6" sx={{ fontWeight: 550 }}>
            Answer
          </Typography>
        </Stack>

        <ReactMarkdown components={renderers}>
          {el.message}
        </ReactMarkdown>
      </Stack>

      <Box
          p={1}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Tooltip title="Copy" arrow placement="top">
            <IconButton size="small" onClick={handleCopy}>
              <Copy size={20} color="#3A555A" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Good Response" arrow placement="top">
            <IconButton size="small" onClick={handleGoodResponse}>
              <ThumbsUp size={20} color="#3A555A" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Report" arrow placement="top">
            <IconButton size="small" onClick={handleReport}>
              <ThumbsDown size={20} color="#3A555A" />
            </IconButton>
          </Tooltip>
        </Box>

        <Divider sx={{ m: 1 }} />
    </>
  )
}

export { AnswerMsg, PromptMsg }