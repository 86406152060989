import React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { PromptMsg, AnswerMsg } from './MsgTypes';
import Logo from '../../assets/Images/logo.ico';

const Messages = ({ messages }) => {
  if (messages.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={Logo} alt="CelcomDigi Logo" style={{ width: '100px', marginBottom: '16px' }} />
        <Typography variant="h5" color="text.primary" gutterBottom>
          Welcome to IDA
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          By CelcomDigi
        </Typography>
      </Box>
    );
  }

  return (
    <Stack direction="column">
      {messages.map((el, index) => {
        switch (el.type) {
          case 'prompt':
            return <PromptMsg key={index} el={el} />;
          case 'answer':
            return <AnswerMsg key={index} el={el} />;
          default:
            return null;
        }
      })}
    </Stack>
  );
};

export default Messages;