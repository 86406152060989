// src/utils/auth.js

import { LOGIN_API_URL } from "../config"

const getUserProfile = async () => {
  try {
    const response = await fetch(LOGIN_API_URL, {
      method: 'GET',
      credentials: 'include',
    });

    return response;
  } catch (error) {
    console.error('Error checking user login:', error);
    return null;
  }
};

export { getUserProfile };