import React, { useEffect, useState } from 'react';
import { getUserProfile } from './utils/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography, Button } from '@mui/material';

// routes
import Router from "./routes";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const userLogin = async () => {
    try {
      const loginResult = await getUserProfile();
      console.log(loginResult);

      if (loginResult) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (err) {
      console.log(err);
      setIsLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    userLogin();
  }, []);

  return (

    <>
      {isLoading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <CircularProgress /> {/* Loading spinner */}
          <Typography variant="h6" marginTop={2}>
            Checking login status...
          </Typography>
        </Box>
      ) : isLoggedIn ? (
        <Router />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          textAlign="center"
        >
          <Typography variant="h6" marginBottom={2}>
            Please log in to access the application.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://app.celcomdigi.tech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Login
          </Button>
        </Box>
      )}
    </>

  );
}

export default App;
