// DEFAULT ROOT PATH
export const DEFAULT_PATH = '/'; 

// WebSocket API URL (AI Response)
//export const WEBSOCKET_API_URL = "wss://utmoz6y7v9.execute-api.ap-southeast-1.amazonaws.com/dev/?api-key=O4%21XmYjBvK7%2Fa3lQbMUfRrPSo2c2gW1GTyjjCjdaJdwCOLlJjitXD23TeMwhphULTFq-GobnFmI59mJYcxF%21JVh5Cs%3D3chG%21j%3DKUcd-s4ev4kL6f5g-dLSxIBhn3zg7z&mode=dev";
export const WEBSOCKET_API_URL_PROD = "wss://ws.api.celcomdigi.tech";
export const WEBSOCKET_API_URL_DEV = "wss://o7y7li78of.execute-api.ap-southeast-1.amazonaws.com/dev";
export const WEBSOCKET_API_URL_KEY_DEV = "O4!XmYjBvK7/a3lQbMUfRrPSo2c2gW1GTyjjCjdaJdwCOLlJjitXD23TeMwhphULTFq-GobnFmI59mJYcxF!JVh5Cs=3chG!j=KUcd-s4ev4kL6f5g-dLSxIBhn3zg7z";

// REST API URL (Collect Feedback)
export const REST_API_URL = "https://m6bduqqo8h.execute-api.ap-southeast-1.amazonaws.com/prod/messages"

// Login API URL
export const LOGIN_API_URL = "https://auth.api.celcomdigi.tech/login/profile"

export const MODE = "prod"