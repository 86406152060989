// src/utils/api.js

import { REST_API_URL } from "../config"

export const sendFeedback = async (type, message_id) => {
  try {
    const response = await fetch(REST_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: type,
        message_id: message_id,}),
    });

    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error sending feedback:', error);
    throw error;
  }
};